
export const usePlansStore = defineStore('plans', {
  state: () => {
    return {
      plans: [],
      total: 0,
      isPlansLoading: false,
    };
  },

  actions: {
    async fetchPlans(params) {
      this.isPlansLoading = true;

      this.plans = [];
      this.total = 0;

      const response = await useApi.get('/plans', {
        params,
      }).catch(() => {
        useToast.error(useT('plan.fetchError'));
      });

      if (response) {
        this.plans = response.results;
        this.total = response.total;
      }

      this.isPlansLoading = false;

      return response;
    },

    openUpgrade(plan, { openTier } = {}) {
      if (plan?.type === 'subscription') {
        useModalStore().open({
          type: 'success',
          icon: 'infinity',
          title: useT('plan.upgradeSubscription'),
          description: useT('plan.upgradeSubscription.description'),
          submitButtonText: useT('plan.upgradeSubscription.contactSupport'),
          cancelButtonText: useT('poly.close'),
          onSubmit: () => {
            window.open(`mailto:${useT('attendu.supportEmail')}`, '_blank');
          },
        });
      } else if (plan?.type === 'singleUse') {
        if (openTier) {
          useDialogStore().open('planOrder', { tier: openTier, currentPlan: plan });
        } else {
          useDialogStore().open('planUpgrade', { plan });
        }
      }
    },

    async createSingleUsePlan(body) {
      const response = await useApi.post('/plans/single-use', {
        body,
      }).catch(() => {
        useToast.error(useT('poly.errorOccurred'));
      });

      return response;
    },
  },

  getters: {

  },
});
